import { formatDate, DATE_FORMATS } from '@akin/core-lib/utils/dateTime';
import { TIME_PERIOD } from '../common/time-range-filter/constants';

function createHourChartData(byDate) {
  const list = [];

  Object.keys(byDate).forEach((date) => {
    const byHour = { ...byDate[date].byHour };
    Object.keys(byHour).forEach((key) => {
      list.push({
        x: `${formatDate(date, 'MMM dd', 'yyyy-MM-dd')} - ${key.padStart(
          2,
          '0'
        )}:00`,
        y: byHour[key],
      });
    });
  });

  return list;
}

function create24HoursChartData(byDate) {
  const list = [];

  Object.keys(byDate).forEach((date) => {
    const byHour = { ...byDate[date].byHour };
    Object.keys(byHour).forEach((key) => {
      list.push({
        x: `${key.padStart(2, '0')}:00`,
        y: byHour[key],
      });
    });
  });

  return list;
}

function createDateChartData(data = {}) {
  const chartData = [];
  Object.keys(data).forEach((date) => {
    chartData.push({
      x: formatDate(date, DATE_FORMATS.LONG4, 'yyyy-MM-dd'),
      y: data[date].total,
    });
  });

  return chartData;
}

export function countByHourKeys(data = {}) {
  const { byDate = {} } = data;
  const result = {};
  if (byDate) {
    Object.keys(byDate).forEach((date) => {
      result[date] = Object.keys(byDate[date]?.byHour).length;
    });
    const total = Object.values(result).reduce((acc, curr) => acc + curr, 0);
    return total;
  }
}

export function countByDaysKeys(data = {}) {
  const { byDate = {} } = data;
  if (byDate) {
    return Object.keys(byDate).length;
  }
}

function processHoursFormat(graphDataList = []) {
  const result = [];
  let currentDate = '';
  const graphDataListLength = graphDataList.length;
  for (let i = 0; i < graphDataListLength; i += 1) {
    const theDate = graphDataList[i]?.x;
    if (theDate) {
      const [date, time] = theDate.split(' - ');
      if (date !== currentDate) {
        currentDate = date;
        result.push({ ...graphDataList[i], recent: graphDataList[i]?.x });
      } else {
        result.push({ ...graphDataList[i], recent: time });
      }
    }
    if (!theDate) result.push({ ...graphDataList[i], recent: '' });
  }

  return result;
}

export function getLineGraphAxisBottomFormat({
  period,
  graphDataList = [],
  hourCount,
  dayCount,
}) {
  const { value: periodId } = period;
  let valuesToShow;
  let axisBottomFormat;

  if (
    periodId === TIME_PERIOD.TODAY.value ||
    hourCount <= 24 ||
    periodId === TIME_PERIOD.LAST_MONTH.value
  ) {
    valuesToShow = graphDataList.map((v, i) => (i % 3 !== 0 ? '' : v));
    axisBottomFormat = (v) =>
      valuesToShow.find((vts) => vts.x === v) ? v : '';

    return { axisBottomFormat };
  }

  if (periodId === TIME_PERIOD.LAST_WEEK.value) {
    axisBottomFormat = (v) =>
      graphDataList.find((vts) => vts.x === v) ? v : '';

    return { axisBottomFormat };
  }

  if (hourCount <= 48) {
    valuesToShow = processHoursFormat(
      graphDataList.map((v, i) => (i % 3 !== 0 ? '' : v))
    );

    axisBottomFormat = (v) => {
      const data = valuesToShow.find((vts) => vts.x === v);
      return data?.recent || '';
    };

    return { axisBottomFormat };
  }

  if (dayCount <= 14) {
    axisBottomFormat = (v) =>
      graphDataList.find((vts) => vts.x === v) ? v : '';

    return { axisBottomFormat };
  }
  if (dayCount <= 92) {
    const divisor = Math.ceil(dayCount / 10);
    valuesToShow = graphDataList.map((v, i) => (i % divisor !== 0 ? '' : v));
    axisBottomFormat = (v) =>
      valuesToShow.find((vts) => vts.x === v) ? v : '';

    return { axisBottomFormat };
  }

  return { axisBottomFormat };
}

export function getCountDataList({ data = {} }) {
  const byDate = { ...data?.byDate };

  const hourCount = countByHourKeys(data);

  let graphDataList = [];

  if (hourCount <= 24) {
    return create24HoursChartData(byDate);
  }

  if (hourCount < 48) {
    return createHourChartData(byDate);
  }

  graphDataList = createDateChartData(byDate);

  return graphDataList;
}

import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  tooltip: {
    padding: 10,
    background: '#FFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10,
    maxWidth: '250px',
    borderRadius: 4,
    border: '1px solid #eeeeee',
  },
  box: {
    height: 16,
    width: 16,
  },
}));

export default function Tooltip({ color, children }) {
  const { classes } = useStyles();
  return (
    <div className={classes.tooltip}>
      <div className={classes.box} style={{ backgroundColor: color }} />
      <span>{children}</span>
    </div>
  );
}

import { Trans, t } from '@lingui/macro';

/* eslint-disable import/prefer-default-export */
export const TIME_PERIOD = {
  TODAY: 'today',
  LAST_24_HOURS: 'last_24_hours',
  LAST_WEEK: 'last_week',
  LAST_MONTH: 'last_month',
};

export const TIME_PERIOD_LIST = [
  { id: TIME_PERIOD.LAST_MONTH, label: <Trans>Last month</Trans> },
  { id: TIME_PERIOD.LAST_WEEK, label: <Trans>Last week</Trans> },
  { id: TIME_PERIOD.LAST_24_HOURS, label: <Trans>Last 24 hours</Trans> },
  { id: TIME_PERIOD.TODAY, label: <Trans>Today</Trans> },
];

export const RESPONSE_TIME_BUCKET = {
  mins_under_2: t`Under 2 mins`,
  mins_2_15: t`2-15 mins`,
  mins_15_60: t`15-60 mins`,
  hours_1_6: t`1-6 hours`,
  hours_6_24: t`6-24 hours`,
  hours_over_24: t`More than 24hrs`,
};

export const HUMAN_ALL = {
  all: 'all',
  human: 'human',
  ai: 'ai',
};

export const AI_HUMAN_LABELS = {
  only_ai: t`AI Only`,
  only_human: t`Human Only`,
  ai_human: t`AI + Human`,
};

export const HUMAN_ALL_LV = [
  { value: HUMAN_ALL.all, label: AI_HUMAN_LABELS.ai_human },
  { value: HUMAN_ALL.human, label: AI_HUMAN_LABELS.only_human },
];

export const HUMAN_AI_ALL_LV = [
  { value: HUMAN_ALL.ai, label: AI_HUMAN_LABELS.only_ai },
  { value: HUMAN_ALL.human, label: AI_HUMAN_LABELS.only_human },
  { value: HUMAN_ALL.all, label: AI_HUMAN_LABELS.ai_human },
];

export const MSG_CON = {
  message: 'message',
  conversation: 'conversation',
};

export const MSG_CON_LV = [
  { value: MSG_CON.message, label: t`Messages` },
  { value: MSG_CON.conversation, label: t`Conversations` },
];

export const PERCENT_NUM_CON = {
  number: 'number',
  percent: 'percent',
};
export const PERCENT_NUM_CON_LV = [
  { value: PERCENT_NUM_CON.number, label: 'Number of Conversations' },
  { value: PERCENT_NUM_CON.percent, label: 'Percent of conversations' },
];

import { t } from '@lingui/macro';
import { getMainLayout as MainLayout } from '../components/layouts';
import { ContentTitle } from '../components/layouts/MainLayout';
import HomePage from '../components/home';

export default function SpaceHomePage() {
  return (
    <>
      <ContentTitle pageName={t`Home`} />
      <HomePage />
    </>
  );
}

SpaceHomePage.getLayout = MainLayout;

import {
  parse,
  format,
  add,
  sub,
  getDay,
  addMinutes,
  getMonth,
  getYear,
  endOfMonth,
  startOfMonth,
  startOfYear,
  differenceInDays,
  isAfter,
  isToday,
  isYesterday,
  startOfDay,
  endOfDay,
  isWithinInterval,
} from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import ct from 'countries-and-timezones';

export const DATE_FORMATS = {
  DEFAULT: 'dd-MM-yyyy',
  DEFAULT2: 'yyyy-MM-dd',
  LONG: 'EEE MMM dd, yyyy',
  LONG2: 'EEEE, dd MMMM yyyy',
  LONG3: 'dd LLL yyyy',
  LONG4: 'MMM dd, yyyy',
  MONTH: 'MMM',
  DAY: 'dd',
};

export const TIME_FORMATS = {
  DEFAULT: 'HH:mm',
  LONG: 'hh:mmaaa',
  LONG2: 'h:mm aa',
};

export function parseDate(dateString, dateFormat = DATE_FORMATS.DEFAULT) {
  return parse(dateString, dateFormat, new Date());
}

// If dateString is not in `new Date()` format then provide the
// dateString format as parseFormat param
export function formatDate(
  dateString,
  outputFormat = DATE_FORMATS.DEFAULT,
  parseFormat = null
) {
  try {
    const date = parseFormat ? parseDate(dateString, parseFormat) : dateString;

    const formattedDate = format(date, outputFormat);
    return formattedDate;
  } catch (error) {
    // eslint-disable-next-line no-console
    // console.error(error);
    return '';
  }
}

export function formatDateInTimezone({
  dateString,
  outputFormat = DATE_FORMATS.DEFAULT,
  parseFormat = null,
  timezone,
}) {
  try {
    const date = parseFormat ? parseDate(dateString, parseFormat) : dateString;
    const formattedDate = formatInTimeZone(date, timezone, outputFormat);
    return formattedDate;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return '';
  }
}

export function getBrowserTimezone() {
  try {
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const parsedTimezone = ct.getTimezone(browserTimezone);

    if (parsedTimezone.aliasOf) return parsedTimezone.aliasOf;

    return parsedTimezone.name;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export function getAllTimezones() {
  const timezones = ct.getAllTimezones();
  return Object.keys(timezones);
}

export function getAllCountries() {
  const countries = ct.getAllCountries();
  return Object.entries(countries).map(([key, value]) => ({
    value: key,
    label: value.name,
  }));
}

export function getCountryName(countryCode = null) {
  try {
    if (!countryCode) return null;
    const countryInfo = ct.getCountry(countryCode.toUpperCase());

    return countryInfo ? countryInfo.name : countryCode;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error getting country information:', error);
    return null;
  }
}

export function convertMinsToHrsMins(mins, padZeros = false) {
  let h = Math.floor(mins / 60);
  let m = mins % 60;

  if (padZeros) {
    h = h < 10 ? `0${h}` : h; // (or alternatively) h = String(h).padStart(2, '0')
    m = m < 10 ? `0${m}` : m; // (or alternatively) m = String(m).padStart(2, '0')
  }

  return { hours: h, minutes: m };
}

export function addDaysToDate(date, duration) {
  const newDate = add(new Date(date), { days: duration });
  const formattedDate = formatDate(newDate, DATE_FORMATS.DEFAULT2);
  return formattedDate;
}

export function subDaysFromDate(date, duration) {
  const newDate = sub(new Date(date), { days: duration });
  const formattedDate = formatDate(newDate, DATE_FORMATS.DEFAULT2);
  return formattedDate;
}

export function getStartOfMonth(date) {
  const newDate = startOfMonth(new Date(date));
  const formattedDate = formatDate(newDate, DATE_FORMATS.DEFAULT2);
  return formattedDate;
}

export function getStartOfYear(date) {
  const newDate = startOfYear(new Date(date));
  const formattedDate = formatDate(newDate, DATE_FORMATS.DEFAULT2);
  return formattedDate;
}

export function getEndOfMonth(date) {
  const newDate = endOfMonth(new Date(date));
  const formattedDate = formatDate(newDate, DATE_FORMATS.DEFAULT2);
  return formattedDate;
}

export function isDateToday(date) {
  const today = formatDate(new Date(), DATE_FORMATS.DEFAULT2);
  const testDate = formatDate(new Date(date), DATE_FORMATS.DEFAULT2);
  return today === testDate;
}

export function getDateDiff({ startDate, endDate, type = 'days' }) {
  if (type === 'days') return differenceInDays(endDate, startDate);
}

export function getDayInfo(date) {
  const dateObj = new Date(date);
  const dayInfo = {
    day: getDay(dateObj),
    month: getMonth(dateObj),
    year: getYear(dateObj),
    dayOfWeek: formatDate(dateObj, 'EEE'),
  };
  return dayInfo;
}

export function getEndTime(time, duration) {
  try {
    if (duration < 1) return '';
    const [hours, minutes] = time.split(':');
    const date = new Date(0, 0, 0, hours, minutes);

    const endTime = addMinutes(date, duration);
    return format(endTime, 'HH:mm');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return '';
  }
}

export function getTimeRange({ startTime, duration }) {
  const endTime = getEndTime(startTime, duration);
  const start = formatDate(startTime, TIME_FORMATS.LONG, TIME_FORMATS.DEFAULT);

  // All day event check
  if (duration < 1) return start;

  const end = formatDate(endTime, TIME_FORMATS.LONG, TIME_FORMATS.DEFAULT);
  return `${start} - ${end}`;
}

export function getDateObject(dateTimeString) {
  const [date, time] = dateTimeString.split(' ');
  const [year, month, day] = date.split('-');
  const [hours, minutes] = time.split(':');
  // console.log(`new Date(${year}, ${month - 1}, ${day}, ${hours}, ${minutes})`);
  return new Date(year, month - 1, day, hours, minutes);
}

export function isDateWithInRange(date, startDate, endDate) {
  return isWithinInterval(new Date(date), {
    start: startOfDay(new Date(startDate)),
    end: endOfDay(new Date(endDate)),
  });
}

export { isAfter, isToday, isYesterday };

/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */

import { t } from '@lingui/macro';
import { getLineGraphData } from './helpers';

const BarEmptyState = [{ id: 'No data', label: t`No data`, value: 0 }];

function normalizeSubSpaces(subSpaces = []) {
  return subSpaces.reduce((acc, { name, currency, ai_subtenant_code }) => {
    // eslint-disable-next-line no-param-reassign
    acc[ai_subtenant_code] = { name, currency };
    return acc;
  }, {});
}

function getResponsesGraph(responseType = {}, groupSpaces = {}) {
  return Object.keys(responseType).map((key) => ({
    id: groupSpaces[key]?.name || key, // subTenant name of the key i.e. subtenant code
    AI: responseType[key].ai,
    Total: responseType[key].total,
  }));
}

const getBarGraphDataViaKeyValue = (data = {}, groupSpaces = {}) => {
  const infoData = Object.keys(data).map((key) => {
    const keyName = groupSpaces[key]?.name || key; // subTenant name of the key i.e. subtenant code
    return {
      id: keyName,
      [keyName]: data[key],
    };
  });

  return infoData.length ? infoData : BarEmptyState;
};

const getBookingEngineValueGraph = (data = {}, groupSpaces = {}) => {
  const infoData = Object.keys(data).map((key) => {
    const keyName = groupSpaces[key]?.name || key; // subTenant name of the key i.e. subtenant code
    return {
      id: keyName,
      [keyName]: data[key],
      currency: groupSpaces[key]?.currency,
    };
  });

  return infoData.length ? infoData : BarEmptyState;
};

function getAvgSatisfactionScoreGraph(data = {}, groupSpaces = {}) {
  return Object.keys(data).map((key) => ({
    id: groupSpaces[key]?.name || key, // subTenant name of the key i.e. subtenant code
    AI: data[key].ai,
    Human: data[key].human,
  }));
}

function getProblemSolvedPercentGraph(data = {}, groupSpaces = {}) {
  return Object.keys(data).map((key) => ({
    id: groupSpaces[key]?.name || key, // subTenant name of the key i.e. subtenant code
    AI: data[key].ai,
    Human: data[key].human,
  }));
}

export function getGroupGraphData({ period, data = {}, subSpaces = [] }) {
  const groupSpaces = normalizeSubSpaces(subSpaces);
  return {
    countGraphData: getLineGraphData({
      period,
      data: data?.groupData,
    }),
    responsesGraphData: getResponsesGraph(
      data?.groupData?.responseType,
      groupSpaces
    ),
    automationPercentGraphData: getBarGraphDataViaKeyValue(
      data?.groupData?.aiPercent,
      groupSpaces
    ),
    avgSatisfactionScoreGraphData: getAvgSatisfactionScoreGraph(
      data?.groupData?.avgSatisfactionScore,
      groupSpaces
    ),
    problemSolvedPercentGraphData: getProblemSolvedPercentGraph(
      data?.groupData?.problemSolvedPercent,
      groupSpaces
    ),
    bookingEngineClickGraphData: getBarGraphDataViaKeyValue(
      data?.groupData?.bookingEngineClicks,
      groupSpaces
    ),
    bookingEngineValueGraphData: getBookingEngineValueGraph(
      data?.groupData?.bookingEngineForwardedValues,
      groupSpaces
    ),
  };
}

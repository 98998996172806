import React, { useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import BarGraph from '../../common/charts/BarGraph';
import GraphInsights from '../GraphInsights';
import { PERCENT_NUM_CON, PERCENT_NUM_CON_LV } from '../constants';
import GraphCard from '../common/GraphCard';
import Tooltip from '../common/Tooltip';

export default function SatisfactionScoreRangeGraph({ graphInfo: data }) {
  const [typeGraph, setTypeGraph] = useState(PERCENT_NUM_CON.number);

  const graphInfo = useMemo(() => {
    return typeGraph === PERCENT_NUM_CON.number ? data.number : data.percent;
  }, [typeGraph, data.number, data.percent]);

  const keys = Object.keys(data?.yLabels);
  const colors = ['#4F9581', '#AFC1E1', '#E4ACA9', '#F2D074'];

  const tooltipComponent = ({ id, value, color }) => (
    <Tooltip color={color}>
      {data?.yLabels?.[id]}: {value}
    </Tooltip>
  );

  return (
    <>
      <GraphCard.Header
        showSwitch
        switchList={PERCENT_NUM_CON_LV}
        onSwitchChange={setTypeGraph}
        selected={typeGraph}
      >
        <GraphInsights title={t`Satisfaction Score Range`} />
      </GraphCard.Header>

      <GraphCard.Content>
        <BarGraph
          data={graphInfo}
          keys={keys}
          indexBy="id"
          tooltip={tooltipComponent}
          colors={colors}
          showLegends
          groupMode="grouped"
          graphProps={{
            axisLeft: {
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend:
                typeGraph === PERCENT_NUM_CON.number
                  ? t`Number of conversations`
                  : t`Percent of conversations`,
              legendPosition: 'middle',
              legendOffset: -40,
              truncateTickAt: 0,
              format: (e) => Math.floor(e) === e && e,
            },
            legends: [
              {
                dataFrom: 'keys',
                data: keys.map((id, index) => ({
                  color: colors[index],
                  id,
                  label: data?.yLabels?.[id],
                })),
                anchor: 'top',
                direction: 'row',
                justify: false,
                translateX: 40,
                translateY: -30,
                itemWidth: 200,
                itemHeight: 20,
                itemsSpacing: 4,
                symbolSize: 20,
                symbolShape: 'circle',
                itemDirection: 'left-to-right',
                itemTextColor: '#777',
              },
            ],
          }}
        />
      </GraphCard.Content>
    </>
  );
}

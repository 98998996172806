// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/pie
import { ResponsivePie } from '@nivo/pie';

const legends = [
  {
    anchor: 'top-right',
    direction: 'column',
    justify: false,
    translateX: 50,
    translateY: 0,
    itemWidth: 100,
    itemHeight: 20,
    itemsSpacing: 0,
    symbolSize: 20,
    itemDirection: 'left-to-right',
  },
];

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export default function PieChart({
  data,
  showLegend = false,
  tooltip,
  graphData = {},
  margin = { top: 40, right: 50, bottom: 80, left: -80 },
}) {
  return (
    <ResponsivePie
      data={data}
      margin={margin}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      colors={{ scheme: 'paired' }}
      borderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]],
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsStraightLength={5}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: 'color',
        modifiers: [['darker', 2]],
      }}
      {...(showLegend && { legends })}
      {...(tooltip && { tooltip })}
      {...graphData}
    />
  );
}

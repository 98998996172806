import { useMemo, useState } from 'react';
import { createStyles } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { t } from '@lingui/macro';

import LineGraph from '../../common/charts/LineGraph';
import GraphInsights from '../GraphInsights';
import { MSG_CON, MSG_CON_LV } from '../constants';
import GraphCard from '../common/GraphCard';

const useStyles = createStyles((theme) => ({
  countDescription: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    [theme.fn.smallerThan('xs')]: {
      gap: 0,
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: 10,
    },
  },
  percentValue: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 32,
    padding: '5px 10px',
    backgroundColor: '#ddd',
    fontWeight: 600,
  },
}));

function PercentChangeComponent({ percentChange }) {
  if (percentChange < 0) {
    return <>&#8595; {percentChange * -1}%</>;
  }
  if (percentChange > 0) {
    return <>&#8593; {percentChange}%</>;
  }
  return null;
}

function CountDescription({ totalTitleKey, data }) {
  const { classes } = useStyles();
  return (
    <div className={classes.countDescription}>
      {t`Total number of ${totalTitleKey}: ${data?.total} `}
      {data?.percentChange ? (
        <div className={classes.percentValue}>
          <PercentChangeComponent percentChange={data?.percentChange} />
        </div>
      ) : null}
    </div>
  );
}

export default function CountGraph({ data }) {
  const isMobileScreen = useMediaQuery('(max-width: 830px)');
  const [typeGraph, setTypeGraph] = useState(MSG_CON.message);

  const { axisBottomFormat } = data;

  const { data: graphInfo, overview } = useMemo(() => {
    return typeGraph === MSG_CON.message ? data.messages : data.conversations;
  }, [typeGraph, data.messages, data.conversations]);

  return (
    <>
      <GraphCard.Header
        showSwitch
        switchList={MSG_CON_LV}
        onSwitchChange={setTypeGraph}
        selected={typeGraph}
      >
        <GraphInsights
          title={overview?.count?.title}
          description={
            <CountDescription
              totalTitleKey={overview?.count?.totalTitleKey}
              data={overview?.count}
            />
          }
        />
        <GraphInsights
          title={overview?.aiCount?.title}
          description={
            <CountDescription
              totalTitleKey={overview?.aiCount?.totalTitleKey}
              data={overview?.aiCount}
            />
          }
        />
      </GraphCard.Header>

      <GraphCard.Content>
        <LineGraph
          data={graphInfo}
          axisBottomFormat={axisBottomFormat}
          isMobileScreen={isMobileScreen}
          colors={['#FD60A9', '#346BFE']}
          indexBy="date"
          graphData={{
            legends: [
              {
                dataFrom: 'keys',
                anchor: 'top',
                direction: 'row',
                justify: false,
                translateX: 40,
                translateY: -30,
                itemWidth: 200,
                itemHeight: 20,
                itemsSpacing: 4,
                symbolSize: 20,
                symbolShape: 'circle',
                itemDirection: 'left-to-right',
                itemTextColor: '#777',
              },
            ],
          }}
        />
      </GraphCard.Content>
    </>
  );
}

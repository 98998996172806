import { createStyles } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import ChoroplethChart from '../../common/charts/Map';

const useStyles = createStyles((theme) => ({
  container: {
    height: 500,
    [theme.fn.smallerThan('sm')]: {
      width: 700,
    },
  },
}));

const nivoColors = [
  '#d76445',
  '#f47560',
  '#e8c1a0',
  '#97e3d5',
  '#61cdbb',
  '#00b0a7',
];

export default function CountryGraph({ graphInfo }) {
  const { classes } = useStyles();
  const isSmallScreen = useMediaQuery('(max-width: 930px)');

  const maxDataValue = graphInfo.length
    ? Math.max(...graphInfo.map((d) => d.value))
    : 1;

  const maxLegendValue =
    maxDataValue % 10 === 0
      ? maxDataValue
      : (parseInt((maxDataValue || 0) / 10, 10) + 1) * 10;

  let numOfColors = 6;
  if (maxLegendValue < 100) {
    numOfColors = 3;
  }

  const colorIndexScale = nivoColors.length / numOfColors;
  const colors = [];
  for (let i = 0; i < numOfColors; i += 1) {
    colors.push(nivoColors[Math.ceil(i * colorIndexScale)]);
  }

  const graphProps = {
    projectionScale: isSmallScreen ? 100 : 120,
    colors,
    domain: [0, maxLegendValue],
    valueFormat: maxLegendValue < 100 ? 'd' : '.2s',
    unknownColor: '#cccccc',
    graticuleLineColor: '#dddddd',
    borderColor: '#152538',
  };

  return (
    <div className={classes.container}>
      <ChoroplethChart data={graphInfo} graphProps={graphProps} showLegends />
    </div>
  );
}

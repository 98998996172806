import { Text } from '@akin/ui-lib';
import { useMediaQuery } from '@mantine/hooks';

import Tooltip from '../../common/Tooltip';
import BarGraph from '../../../common/charts/BarGraph';
import BarGraphTickTextRender from '../../common/BarGraphTickTextRender';
import { AI_HUMAN_LABELS } from '../../constants';

const tooltipComponent = ({ id, value, color, data }) => {
  const label =
    id === 'AI' ? AI_HUMAN_LABELS.only_ai : AI_HUMAN_LABELS.ai_human;
  return (
    <Tooltip color={color}>
      <div>
        <Text size={12}>{data.id}</Text>
      </div>
      <b>{label}:</b> {value}
    </Tooltip>
  );
};
const colors = ['#1f78b4', '#a6cee3'];
const keys = ['Human', 'AI'];

export default function AverageSatisfactionScoreGraph({
  data: graphInfo = [],
}) {
  const isMobileScreen = useMediaQuery('(max-width: 430px)');

  const isVerticalTick = isMobileScreen || graphInfo.length > 6;

  const graphProps = {
    axisLeft: {
      format: (e) => Math.floor(e) === e && e,
    },
    ...(!isVerticalTick && {
      axisBottom: {
        tickSize: 0,
        tickPadding: 25,
        tickRotation: 0,
        renderTick: (props) => (
          <BarGraphTickTextRender {...props} maxLineLength={15} maxLines={6} />
        ),
      },
    }),
    ...(isVerticalTick && {
      margin: { top: 50, right: 50, bottom: 150, left: 60 },
    }),
    legends: [
      {
        dataFrom: 'keys',
        data: keys.map((id, index) => ({
          color: colors[index],
          id,
          label:
            id === 'AI' ? AI_HUMAN_LABELS.only_ai : AI_HUMAN_LABELS.ai_human,
        })),
        anchor: 'top',
        direction: 'row',
        justify: false,
        translateX: 40,
        translateY: -30,
        itemWidth: 200,
        itemHeight: 20,
        itemsSpacing: 4,
        symbolSize: 20,
        symbolShape: 'circle',
        itemDirection: 'left-to-right',
        itemTextColor: '#777',
      },
    ],
    theme: {
      axis: {
        ticks: {
          text: {
            fontSize: 8, // Adjust the font size here
          },
        },
      },
    },
  };

  return (
    <BarGraph
      data={graphInfo}
      indexBy="id"
      keys={keys}
      tooltip={tooltipComponent}
      showLegends
      colors={colors}
      groupMode="grouped"
      xAxisTickRotation={45}
      graphProps={graphProps}
    />
  );
}

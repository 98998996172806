import { Trans, t } from '@lingui/macro';

export const LIST_TYPE = {
  default: 'default',
  calendar: 'calendar',
  search: 'search',
};

export const TIME_PERIOD = {
  TODAY: {
    value: 'TODAY',
    label: <Trans>Today</Trans>,
  },
  LAST_24_HOURS: {
    value: 'LAST_24_HOURS',
    label: <Trans>Last 24 hours</Trans>,
  },
  LAST_WEEK: {
    value: 'LAST_WEEK',
    label: <Trans>Last week</Trans>,
  },
  LAST_MONTH: {
    value: 'LAST_MONTH',
    label: <Trans>Last month</Trans>,
  },
};

export const TIME_PERIOD_LIST = [
  TIME_PERIOD.TODAY,
  TIME_PERIOD.LAST_24_HOURS,
  TIME_PERIOD.LAST_WEEK,
  TIME_PERIOD.LAST_MONTH,
];

export const DEFAULT_RELATIVE_PERIODS = {
  '1h': 'Last hour',
  '24h': 'Last 24 hours',
  '7d': 'Last 7 days',
  '14d': 'Last 14 days',
  '30d': 'Last 30 days',
  '90d': 'Last 90 days',
};

export const INTERVALS = {
  today: { id: 'today', label: t`today` },
  minute: { id: 'minute', label: t`minutes` },
  hour: { id: 'hour', label: t`hours` },
  day: { id: 'day', label: t`days` },
  week: { id: 'week', label: t`weeks` },
  month: { id: 'month', label: t`months` },
};

export const IntervalVariationsByLanguage = {
  en: {
    minute: ['m', 'mi', 'min', 'minu', 'minut', 'minute', 'minutes'],
    hour: ['h', 'ho', 'hou', 'hour', 'hours'],
    day: ['d', 'da', 'day', 'days'],
    week: ['w', 'we', 'wee', 'week', 'weeks'],
    month: ['mo', 'mon', 'mont', 'month', 'months'],
  },
  es: {
    minute: ['m', 'mi', 'min', 'minu', 'minuto', 'minutos'],
    hour: ['h', 'ho', 'hor', 'hora', 'horas'],
    day: ['d', 'di', 'dia', 'dias'],
    week: ['s', 'se', 'sem', 'sema', 'seman', 'semana', 'semanas'],
    month: ['m', 'me', 'mes', 'meses'],
  },
  de: {
    minute: ['m', 'mi', 'min', 'minu', 'minute', 'minuten'],
    hour: ['h', 'st', 'stu', 'stun', 'stund', 'stunde', 'stunden'],
    day: ['t', 'ta', 'tag', 'tage'],
    week: ['w', 'wo', 'woch', 'woche', 'wochen'],
    month: ['m', 'mo', 'mon', 'mona', 'monat', 'monate'],
  },
  it: {
    minute: ['m', 'mi', 'min', 'minu', 'minut', 'minuti'],
    hour: ['h', 'or', 'ora', 'ore'],
    day: ['g', 'gi', 'gio', 'giorn', 'giorno', 'giorni'],
    week: ['s', 'set', 'sett', 'setti', 'settim', 'settimana', 'settimane'],
    month: ['m', 'me', 'mes', 'mesi'],
  },
};

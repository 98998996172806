import { createStyles } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { t } from '@lingui/macro';

import LineGraph from '../../common/charts/LineGraph';
import GraphInsights from '../GraphInsights';
import GraphCard from '../common/GraphCard';

const useStyles = createStyles((theme) => ({
  countDescription: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    [theme.fn.smallerThan('xs')]: {
      gap: 0,
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: 10,
    },
  },
  percentValue: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 32,
    padding: '5px 10px',
    backgroundColor: '#ddd',
    fontWeight: 600,
  },
}));

function PercentChangeComponent({ percentChange }) {
  if (percentChange < 0) {
    return <>&#8595; {percentChange * -1}%</>;
  }
  if (percentChange > 0) {
    return <>&#8593; {percentChange}%</>;
  }
  return null;
}

function CountDescription({ description, percentChange }) {
  const { classes } = useStyles();
  return (
    <div className={classes.countDescription}>
      {description}
      {percentChange ? (
        <div className={classes.percentValue}>
          <PercentChangeComponent percentChange={percentChange} />
        </div>
      ) : null}
    </div>
  );
}

export default function BookingEngineClicks({ data }) {
  const isMobileScreen = useMediaQuery('(max-width: 830px)');

  const { data: graphInfo, overview, axisBottomFormat } = data;

  return (
    <>
      <GraphCard.Header>
        <GraphInsights
          title={overview?.title}
          description={
            <CountDescription
              description={t`Total number of clicks to booking engine: ${overview.total}`}
              percentChange={overview?.percentChange}
            />
          }
        />
      </GraphCard.Header>

      <GraphCard.Content>
        <LineGraph
          data={graphInfo}
          axisBottomFormat={axisBottomFormat}
          axisBottomRotation={{
            tickRotation: 90,
            legendOffset: -80,
          }}
          isMobileScreen={isMobileScreen}
          colors={['#FD60A9']}
          indexBy="date"
          graphData={{
            margin: {
              top: 50,
              right: isMobileScreen ? 20 : 50,
              bottom: 80,
              left: isMobileScreen ? 20 : 60,
            },
          }}
        />
      </GraphCard.Content>
    </>
  );
}

import { Button } from '@akin/ui-lib';
import { createStyles } from '@mantine/core';
import FormError from '../common/form/FormError';
import InputLabel from '../common/form/InputLabel';

const useStyles = createStyles(() => ({
  optionContainer: {
    marginBottom: 10,
  },
  options: {
    display: 'flex',
  },
  optionButton: {
    borderRadius: 0,
    minWidth: 80,
    padding: 10,
    fontSize: 12,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:first-of-type': {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
    '&:last-child': {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
    },
    '&.selected': {
      backgroundColor: '#000',
      color: '#fff',
    },
  },
}));

export default function CustomSwitch({
  label,
  error,
  selected,
  onSelect,
  buttonList = [],
  description,
}) {
  const { cx, classes } = useStyles();

  return (
    <div className={classes.optionContainer}>
      {label && <InputLabel label={label} error={!!error} />}
      <div className={classes.options}>
        {buttonList.map((button) => (
          <Button
            key={button.value}
            className={cx(classes.optionButton, {
              selected: button.value === selected,
            })}
            onClick={() => onSelect(button.value)}
            variant="outline"
          >
            {button.label}
          </Button>
        ))}
      </div>
      {error && <FormError error={error} />}
      {description}
    </div>
  );
}

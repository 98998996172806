import { Text } from '@akin/ui-lib';
import { useMediaQuery } from '@mantine/hooks';

import Tooltip from '../../common/Tooltip';
import BarGraph from '../../../common/charts/BarGraph';
import BarGraphTickTextRender from '../../common/BarGraphTickTextRender';

const tooltipComponent = ({ id, value, color, data }) => {
  return (
    <Tooltip color={color}>
      <Text size={12}>{data.id}</Text>
      <b>{id}:</b> {value}
    </Tooltip>
  );
};

const colors = ['#1f78b4'];

export default function BookingEngineClickGraph({ data: graphInfo = [] }) {
  const isMobileScreen = useMediaQuery('(max-width: 430px)');

  const isVerticalTick = isMobileScreen || graphInfo.length > 6;

  const graphProps = {
    axisLeft: {
      format: (e) => Math.floor(e) === e && e,
    },
    ...(!isVerticalTick && {
      axisBottom: {
        tickSize: 0,
        tickPadding: 25,
        tickRotation: 0,
        renderTick: (props) => (
          <BarGraphTickTextRender {...props} maxLineLength={15} maxLines={6} />
        ),
      },
    }),
    ...(isVerticalTick && {
      margin: { top: 50, right: 50, bottom: 150, left: 60 },
    }),
    theme: {
      axis: {
        ticks: {
          text: {
            fontSize: 8, // Adjust the font size here
          },
        },
      },
    },
  };

  return (
    <BarGraph
      data={graphInfo}
      keys={graphInfo.map((info) => info.id)}
      indexBy="id"
      colors={colors}
      tooltip={tooltipComponent}
      xAxisTickRotation={45}
      graphProps={graphProps}
    />
  );
}

import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  tickText: {
    fontSize: 10,
  },
}));

function getTspanGroups(value, maxLineLength, maxLines = 2) {
  const words = value.split(' ');

  let currLine = '';

  const lines = words.reduce((result, word) => {
    if ((word + currLine).length > maxLineLength && currLine !== '') {
      result.push(currLine);
      currLine = word;
    } else {
      currLine = `${currLine} ${word}`;
    }
    return result;
  }, []);

  lines.push(currLine);
  const slicedLines = lines.slice(0, maxLines);
  const children = [];
  let dy = 0;

  for (let i = 0; i < slicedLines.length; i += 1) {
    children.push(
      <tspan x={0} dy={dy} key={i}>
        {i === maxLines - 1 && lines.length > maxLines
          ? `${slicedLines[i].slice(0, maxLineLength - 3)}...`
          : slicedLines[i]}
      </tspan>
    );
    dy = 15;
  }
  return children;
}

export default function BarGraphTickTextRender({
  opacity,
  textAnchor,
  textBaseline,
  textX,
  textY,
  theme,
  value,
  x,
  y,
  maxLineLength,
  maxLines = 2,
}) {
  const { classes } = useStyles();
  return (
    <g transform={`translate(${x},${y})`} style={{ opacity }}>
      <text
        alignmentBaseline={textBaseline}
        style={theme?.axis?.ticks?.text}
        textAnchor={textAnchor}
        transform={`translate(${textX},${textY})`}
        className={classes.tickText}
      >
        {getTspanGroups(value, maxLineLength, maxLines)}
      </text>
    </g>
  );
}

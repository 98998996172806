import { useMemo, useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { t } from '@lingui/macro';

import GraphInsights from '../GraphInsights';
import { MSG_CON, MSG_CON_LV } from '../constants';
import PieChart from '../../common/charts/PieChart';
import GraphCard from '../common/GraphCard';
import Tooltip from '../common/Tooltip';

const mobileMargin = { top: 40, right: 50, bottom: 80, left: 10 };
const desktopMargin = { top: 40, right: 80, bottom: 80, left: 80 };

const tooltipComponent = (point = {}) => {
  const { id, value, color, data } = point.datum;
  return (
    <Tooltip color={color}>
      {id}: {value} ({data?.percent}%)
    </Tooltip>
  );
};

export default function ConnectToHumanGraph({ graphInfo: data }) {
  const isMobileScreen = useMediaQuery('(max-width: 830px)');
  const [typeGraph, setTypeGraph] = useState(MSG_CON.message);

  const margin = isMobileScreen ? mobileMargin : desktopMargin;

  const { data: graphInfo, overview } = useMemo(() => {
    return typeGraph === MSG_CON.message ? data.messages : data.conversations;
  }, [typeGraph, data.messages, data.conversations]);

  return (
    <>
      <GraphCard.Header
        showSwitch
        switchList={MSG_CON_LV}
        onSwitchChange={setTypeGraph}
        selected={typeGraph}
      >
        <GraphInsights title={t`Connect to Human Requests`} />
        <GraphInsights
          title={t`Total Requests`}
          description={overview?.total || '-'}
        />
        <GraphInsights
          title={t`Average Response Time`}
          description={overview?.avgResponseTime || '-'}
        />
      </GraphCard.Header>

      <GraphCard.Content>
        <PieChart
          data={graphInfo}
          showLegend={false}
          margin={margin}
          graphData={{
            tooltip: tooltipComponent,
            legends: [
              {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 40,
                translateY: 56,
                itemsSpacing: 0,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: 'circle',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemTextColor: '#000',
                    },
                  },
                ],
              },
            ],
          }}
        />
      </GraphCard.Content>
    </>
  );
}

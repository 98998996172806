import { t } from '@lingui/macro';
import { useMediaQuery } from '@mantine/hooks';
import PieChart from '../../common/charts/PieChart';
import Tooltip from '../common/Tooltip';

const tooltipComponent = (point = {}) => {
  const { id, value, color, data } = point.datum;
  return (
    <Tooltip color={color}>
      {id}: {value} {t`Messages`} ({data?.percent}%)
    </Tooltip>
  );
};

export default function AutomationPercentGraph({ graphInfo }) {
  const isMobileScreen = useMediaQuery('(max-width: 425px)');

  return (
    <PieChart
      data={graphInfo}
      showLegend={false}
      {...(isMobileScreen
        ? {
            margin: {
              top: 40,
              right: 50,
              bottom: 80,
              left: 10,
            },
          }
        : { margin: { top: 40, right: 80, bottom: 80, left: 80 } })}
      graphData={{
        tooltip: tooltipComponent,
        legends: [
          {
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateX: 40,
            translateY: 56,
            itemsSpacing: 0,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: '#999',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000',
                },
              },
            ],
          },
        ],
      }}
    />
  );
}

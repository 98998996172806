// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/geo
import { ResponsiveChoropleth } from '@nivo/geo';
import MapFeatures from './MapFeatures';

const legends = [
  {
    anchor: 'bottom-left',
    direction: 'column',
    justify: true,
    translateX: 0,
    translateY: -100,
    itemsSpacing: 0,
    itemWidth: 78,
    itemHeight: 18,
    itemDirection: 'left-to-right',
    itemTextColor: '#444444',
    itemOpacity: 0.85,
    symbolSize: 18,
    effects: [
      {
        on: 'hover',
        style: {
          itemTextColor: '#000000',
          itemOpacity: 1,
        },
      },
    ],
  },
];

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export default function ChoroplethChart(props) {
  const { data = [], graphProps, showLegends } = props;
  return (
    <ResponsiveChoropleth
      data={data}
      features={MapFeatures}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      colors="nivo"
      domain={[0, 1000]}
      label="properties.name"
      valueFormat=".2s"
      projectionTranslation={[0.5, 0.5]}
      projectionRotation={[0, 0, 0]}
      enableGraticule
      borderWidth={0.3}
      {...(showLegends && { legends })}
      {...graphProps}
    />
  );
}

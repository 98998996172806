import { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import { Button, Divider, Input, createStyles } from '@mantine/core';
import { Text } from '@akin/ui-lib';
import { RangeCalendar } from '@mantine/dates';
import useSpace from '../../../hooks/useSpace';
import { showError } from '../../../lib/notifications';

const useStyles = createStyles(() => ({
  container: {
    padding: 10,
  },
  timeInputs: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
  },
  input: {
    marginRight: 10,
  },
  timezone: {
    padding: '2px 0',
  },
  divider: {
    margin: '10px 0',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  backButton: {
    marginRight: 10,
  },
}));

const currentTime = new Date().toLocaleTimeString('en-US', {
  hour12: false,
  hour: '2-digit',
  minute: '2-digit',
});

function RangeSelectorForm({ onBackHandler, onApplyHandler, rangeValues }) {
  const { classes } = useStyles();
  const { currentSpace } = useSpace();

  const [rangeDate, setRangeDate] = useState([new Date(), new Date()]);

  const [startTime, setStartTime] = useState(currentTime);
  const [endTime, setEndTime] = useState(currentTime);

  const handleApply = () => {
    if (!rangeDate[0] || !rangeDate[1]) {
      showError(t`Select date`);
      return;
    }
    if (!startTime || !endTime) {
      showError(t`Select time`);
      return;
    }

    onApplyHandler({
      startDate: rangeDate[0],
      endDate: rangeDate[1],
      startTime,
      endTime,
    });
  };

  useEffect(() => {
    const setRangeValuesHandler = () => {
      if (rangeValues.startDate && rangeValues.endDate) {
        setRangeDate([rangeValues.startDate, rangeValues.endDate]);
      }
      if (rangeValues.startTime) setStartTime(rangeValues.startTime);
      if (rangeValues.endTime) setStartTime(rangeValues.endTime);
    };
    if (rangeValues) {
      setRangeValuesHandler();
    }
  }, [rangeValues]);

  return (
    <div className={classes.container}>
      <RangeCalendar
        minDate={new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000)}
        maxDate={new Date()}
        value={rangeDate}
        onChange={setRangeDate}
      />
      <div className={classes.timeInputs}>
        <Input.Wrapper id="startTime">
          <Input
            type="time"
            id="startTime"
            value={startTime}
            onChange={(event) => setStartTime(event.currentTarget.value)}
            className={classes.input}
          />
        </Input.Wrapper>
        <Input.Wrapper id="endTime">
          <Input
            type="time"
            id="endTime"
            value={endTime}
            onChange={(event) => setEndTime(event.currentTarget.value)}
            className={classes.input}
          />
        </Input.Wrapper>
      </div>
      <div className={classes.timezone}>
        <Text size="xs" color="dimmed">
          {currentSpace.timezone}
        </Text>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.buttonContainer}>
        <Button
          variant="outline"
          className={classes.backButton}
          onClick={onBackHandler}
        >
          Back
        </Button>
        <Button onClick={handleApply}>Apply</Button>
      </div>
    </div>
  );
}

export default RangeSelectorForm;

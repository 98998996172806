import { useMemo } from 'react';
import { t } from '@lingui/macro';

import { getGraphData } from './helpers';
import {
  AI_CHANNELS_CONFIG,
  AI_SERVICES_CHANNELS,
  CHANNEL_MODE,
} from '../ai-service-channels/constants';

import GraphCard from './common/GraphCard';
import GraphInsights from './GraphInsights';
import AutomationPercentGraph from './graphs/AutomationPercent';
import BookingEngineValue from './graphs/BookingEngineValue';
import ConnectToHumanGraph from './graphs/ConnectToHuman';
import CountGraph from './graphs/Count';
import ResponseTimeDistributionGraph from './graphs/ResponseTime';
import AverageSatisfactionScoreGraph from './graphs/AverageSatisfactionScore';
import SatisfactionScoreRangeGraph from './graphs/SatisfactionScoreRange';
import AvgResponseTimeByHourGraph from './graphs/AvgResponseTimeByHour';
import NoResponseCountByHourGraph from './graphs/NoResponseCountByHour';
import LanguageGraph from './graphs/Language';
import ProblemSolvedGraph from './graphs/ProblemSolved';
import TagsGraph from './graphs/Tags';
import GuestInquiryGraph from './graphs/GuestInquiry';
import AIResponsesGraph from './graphs/AIResponses';
import CountryGraph from './graphs/Country';
import BookingEngineClicks from './graphs/BookingEngineClicks';

import useSharedStyles from './sharedStyles';

export default function PropertyGraphs({
  selectedChannel,
  selectedPeriod,
  reportData,
}) {
  const { classes } = useSharedStyles();

  const {
    countGraphData,
    languageGraphData,
    problemSolvedGraphData,
    tagsGraphData,
    aiResponsesWordCountData,
    guestInquiriesWordCountData,
    countriesGraphData,
    avgResponseTimeByHourData,
    noResponseCountByHourData,
    automationPercentData,
    allResponseTime,
    humanResponseTime,
    bookingEngineData,
    connectToHuman,
    avgSatisfactionScoreData,
    satisfactionScoreRangeData,
  } = useMemo(
    () => getGraphData({ period: selectedPeriod, data: reportData }),
    [selectedPeriod, reportData]
  );

  return (
    <>
      <GraphCard>
        <div className={classes.overflowGraph}>
          <CountGraph data={countGraphData} />
        </div>
      </GraphCard>

      <div className={classes.graphRow}>
        <GraphCard className={classes.twoThirdWidth}>
          <ResponseTimeDistributionGraph
            data={{ allResponseTime, humanResponseTime }}
          />
        </GraphCard>

        <GraphCard className={classes.oneThirdWidth}>
          <GraphCard.Header>
            <GraphInsights title={t`AI vs Human Response`} />
          </GraphCard.Header>
          <GraphCard.Content>
            <AutomationPercentGraph graphInfo={automationPercentData} />
          </GraphCard.Content>
        </GraphCard>
      </div>

      {AI_CHANNELS_CONFIG?.[selectedChannel]?.mode === CHANNEL_MODE.chat &&
        selectedChannel !== AI_SERVICES_CHANNELS.whatsApp && (
          <div className={classes.graphRow}>
            <GraphCard className={classes.halfWidth}>
              <BookingEngineClicks
                data={{
                  ...bookingEngineData?.clicks,
                  axisBottomFormat: bookingEngineData.axisBottomFormat,
                }}
              />
            </GraphCard>

            <GraphCard className={classes.halfWidth}>
              <BookingEngineValue
                data={{
                  ...bookingEngineData?.forwardedValues,
                  axisBottomFormat: bookingEngineData.axisBottomFormat,
                }}
              />
            </GraphCard>
          </div>
        )}

      <div className={classes.graphRow}>
        <GraphCard className={classes.halfWidth}>
          <ConnectToHumanGraph graphInfo={connectToHuman} />
        </GraphCard>

        <GraphCard className={classes.halfWidth}>
          <GraphCard.Header>
            <GraphInsights title={t`Average Satisfaction Score`} />
          </GraphCard.Header>
          <GraphCard.Content>
            <AverageSatisfactionScoreGraph
              graphInfo={avgSatisfactionScoreData}
            />
          </GraphCard.Content>
        </GraphCard>
      </div>

      <GraphCard>
        <SatisfactionScoreRangeGraph graphInfo={satisfactionScoreRangeData} />
      </GraphCard>

      <div className={classes.graphRow}>
        <GraphCard className={classes.halfWidth}>
          <GraphCard.Header>
            <GraphInsights title={t`Average Human Response Time (min)`} />
          </GraphCard.Header>
          <GraphCard.Content>
            <AvgResponseTimeByHourGraph graphInfo={avgResponseTimeByHourData} />
          </GraphCard.Content>
        </GraphCard>

        <GraphCard className={classes.halfWidth}>
          <GraphCard.Header>
            <GraphInsights title={t`Visitor Messages with no human response`} />
          </GraphCard.Header>
          <GraphCard.Content>
            <NoResponseCountByHourGraph graphInfo={noResponseCountByHourData} />
          </GraphCard.Content>
        </GraphCard>
      </div>

      <div className={classes.graphRow}>
        <GraphCard className={classes.halfWidth}>
          <GraphCard.Header>
            <GraphInsights title={t`Language`} />
          </GraphCard.Header>
          <GraphCard.Content className={classes.langContent}>
            <LanguageGraph graphInfo={languageGraphData} />
          </GraphCard.Content>
        </GraphCard>

        <GraphCard className={classes.halfWidth}>
          <ProblemSolvedGraph graphInfo={problemSolvedGraphData} />
        </GraphCard>
      </div>

      <GraphCard>
        <GraphCard.Header>
          <GraphInsights title={t`Tags`} />
        </GraphCard.Header>
        <GraphCard.Content>
          <TagsGraph graphInfo={tagsGraphData} />
        </GraphCard.Content>
      </GraphCard>

      <div className={classes.graphRow}>
        <GraphCard className={classes.halfWidth}>
          <GraphCard.Header>
            <GraphInsights title={t`Guest Inquiries`} />
          </GraphCard.Header>
          <div className={classes.overflowGraph}>
            <GuestInquiryGraph graphInfo={guestInquiriesWordCountData} />
          </div>
        </GraphCard>

        <GraphCard className={classes.halfWidth}>
          <GraphCard.Header>
            <GraphInsights title={t`AI Responses`} />
          </GraphCard.Header>
          <div className={classes.overflowGraph}>
            <AIResponsesGraph graphInfo={aiResponsesWordCountData} />
          </div>
        </GraphCard>
      </div>

      <GraphCard>
        <GraphCard.Header>
          <GraphInsights title={t`Countries`} />
        </GraphCard.Header>
        <div className={classes.overflowGraph}>
          <CountryGraph graphInfo={countriesGraphData} />
        </div>
      </GraphCard>
    </>
  );
}

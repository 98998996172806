import { useMediaQuery } from '@mantine/hooks';
import PieChart from '../../common/charts/PieChart';

export default function LanguageGraph({ graphInfo }) {
  const isMobileScreen = useMediaQuery('(max-width: 425px)');

  return (
    <PieChart
      data={graphInfo}
      showLegend={!isMobileScreen}
      {...(isMobileScreen && {
        margin: {
          top: 40,
          right: 50,
          bottom: 80,
          left: 10,
        },
      })}
    />
  );
}

import React from 'react';
import { createStyles } from '@mantine/core';
import { Text } from '@visx/text';
import { scaleLog } from '@visx/scale';
import Wordcloud from '@visx/wordcloud/lib/Wordcloud';

const useStyles = createStyles(() => ({
  wordcloud: {
    display: 'flex',
    flexDirection: 'column',
    userSelect: 'none',
    '& svg': {
      margin: '1rem auto',
      cursor: 'pointer',
    },
    '& label': {
      display: 'inline-flex',
      alignItems: 'center',
      fontSize: '14px',
      marginRight: '8px',
    },
    '& textarea': {
      minHeight: '100px',
    },
  },
}));

const colors = ['#143059', '#2F6B9A', '#82a6c2'];

const fixedValueGenerator = () => 0.5;

export default function WordCloudChart({
  data = [],
  width = 500,
  height = 400,
}) {
  const { classes } = useStyles();

  const fontScale = scaleLog({
    domain: [
      Math.min(...data.map((w) => w.value)),
      Math.max(...data.map((w) => w.value)),
    ],
    range: [10, 50],
  });

  const fontSizeSetter = (datum) => fontScale(datum.value);

  return (
    <div className={classes.wordcloud}>
      <Wordcloud
        words={data}
        width={width}
        height={height}
        fontSize={fontSizeSetter}
        font="Impact"
        padding={2}
        spiral="archimedean"
        rotate={0}
        random={fixedValueGenerator}
      >
        {(cloudWords) =>
          cloudWords.map((w, i) => (
            <Text
              key={w.text}
              fill={colors[i % colors.length]}
              textAnchor="middle"
              transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
              fontSize={w.size}
              fontFamily={w.font}
            >
              {w.text}
            </Text>
          ))
        }
      </Wordcloud>
    </div>
  );
}

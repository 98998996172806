import React from 'react';
import { useMediaQuery } from '@mantine/hooks';
import BarGraph from '../../common/charts/BarGraph';
import BarGraphTickTextRender from '../common/BarGraphTickTextRender';
import Tooltip from '../common/Tooltip';

const tooltipComponent = ({ id, value, color }) => (
  <Tooltip color={color}>
    {id}: {value}
  </Tooltip>
);

export default function TagsGraph({ graphInfo }) {
  const isMobileScreen = useMediaQuery('(max-width: 430px)');

  const isVerticalTick = isMobileScreen || graphInfo.length > 6;

  const graphProps = {
    axisLeft: {
      format: (e) => Math.floor(e) === e && e,
    },
    ...(!isVerticalTick && {
      axisBottom: {
        tickSize: 0,
        tickPadding: 25,
        tickRotation: 0,
        renderTick: (props) => (
          <BarGraphTickTextRender {...props} maxLineLength={6} />
        ),
      },
    }),
    ...(isVerticalTick && {
      margin: { top: 50, right: 50, bottom: 150, left: 60 },
    }),
  };

  return (
    <BarGraph
      data={graphInfo}
      keys={graphInfo.map((info) => info.id)}
      indexBy="id"
      tooltip={tooltipComponent}
      xAxisTickRotation={90}
      graphProps={graphProps}
    />
  );
}

import { useEffect, useMemo, useState } from 'react';
import { Select, Text } from '@akin/ui-lib';
import { createStyles } from '@mantine/core';
import { Trans, t } from '@lingui/macro';

import useUser from '../../hooks/useUser';
import { getChannelList } from './helpers';
import { getHomePageReport } from '../../services/reports';
import useSpace from '../../hooks/useSpace';

import TimeRangeFilter from '../common/time-range-filter';
import { LIST_TYPE, TIME_PERIOD } from '../common/time-range-filter/constants';
import { processReportsParams } from '../common/time-range-filter/utils';
import useSpaceSettings from '../../hooks/useSpaceSettings';

import PropertyGraphs from './PropertyGraphs';
import GroupGraphs from './GroupGraphs';

const useStyles = createStyles((theme) => ({
  root: {
    backgroundColor: theme.white,
    height: '100%',
    width: '100%',
    padding: 40,
    overflowY: 'auto',
    ...theme.other.hideScrollBar,

    '& .mantine-Select-input': {
      border: `1px solid ${theme.black}`,
    },
    [theme.fn.smallerThan('xs')]: {
      padding: 20,
    },
  },
  select: {
    width: 250,
    [theme.fn.smallerThan('xs')]: {
      width: '100%',
    },
  },
  formContainer: {
    position: 'sticky',
    top: '-40px',
    backgroundColor: theme.white,
    zIndex: 1,
    padding: '20px 0',
    borderBottom: '1px solid #eeeeee',
    display: 'flex',
    gap: 10,
    [theme.fn.smallerThan('xs')]: {
      top: '-20px',
      margin: '0 -10px',
      padding: '20px 10px',
      flexDirection: 'column',
    },
  },
}));

export default function HomePage() {
  const { classes } = useStyles();
  const { user } = useUser();
  const { currentSpaceId } = useSpace();
  const {
    spaceSettings: { configuredReportChannels, isGroup },
  } = useSpaceSettings();

  const [selectedPeriod, setSelectedPeriod] = useState({
    ...TIME_PERIOD.LAST_MONTH,
    id: LIST_TYPE.default,
  });
  const [selectedChannel, setSelectedChannel] = useState(undefined);
  const [reportData, setReportData] = useState(null);

  const channelList = useMemo(
    () => getChannelList(configuredReportChannels),
    [configuredReportChannels]
  );

  const onFetchReport = async () => {
    try {
      const payload = processReportsParams(selectedPeriod);
      const response = await getHomePageReport({
        ...payload,
        channel: selectedChannel || undefined,
      });
      setReportData(response);
    } catch (error) {
      // showError(error.message);
      // eslint-disable-next-line no-console
      console.error(error.message);
    }
  };

  const onSelectChannel = (channelID) => {
    setSelectedChannel(channelID);
  };

  const onSelectPeriod = (periodID) => {
    setSelectedPeriod(periodID);
  };

  useEffect(() => {
    if (currentSpaceId && selectedChannel)
      onFetchReport(selectedPeriod, selectedChannel);
  }, [selectedPeriod, selectedChannel, currentSpaceId]);

  useEffect(() => {
    if (channelList.length && !selectedChannel)
      setSelectedChannel(channelList[0].value);
  }, [channelList]);

  return (
    <div className={classes.root}>
      <Text weight={600} size="lg">
        <Trans>Hi {user.full_name}!</Trans>
      </Text>
      <Text size="xs" mb="md">
        <Trans>Here are some insights for you.</Trans>
      </Text>

      <div className={classes.formContainer}>
        <Select
          id="channel"
          name="channel"
          data={channelList}
          maxDropdownHeight={280}
          onChange={(value) => onSelectChannel(value)}
          mb="md"
          value={selectedChannel}
          withinPortal
          required
          {...(!channelList.length && {
            placeholder: t`No channels configured`,
            disabled: true,
          })}
          className={classes.select}
        />

        <TimeRangeFilter
          disabled={!channelList.length}
          onClick={onSelectPeriod}
          selected={selectedPeriod}
        />
      </div>

      {isGroup ? (
        <GroupGraphs
          selectedChannel={selectedChannel}
          selectedPeriod={selectedPeriod}
          reportData={reportData}
        />
      ) : (
        <PropertyGraphs
          selectedChannel={selectedChannel}
          selectedPeriod={selectedPeriod}
          reportData={reportData}
        />
      )}
    </div>
  );
}

import { createStyles } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import WordCloudChart from '../../common/charts/WordCloud';

const useStyles = createStyles(() => ({
  container: {
    height: 400,
  },
}));

export default function GuestInquiryGraph({ graphInfo }) {
  const { classes } = useStyles();
  const isMobileScreen = useMediaQuery('(max-width: 430px)');

  return (
    <div className={classes.container}>
      <WordCloudChart
        data={graphInfo}
        height={400}
        width={isMobileScreen ? 260 : 500}
      />
    </div>
  );
}
